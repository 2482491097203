import { EnvironmentTargetInterface } from '@environments/assets/environment-target.interface';
import { IdApiInterface } from '@environments/assets/environment.interface';

export const idApiTargets: EnvironmentTargetInterface = {
  build: {
    minSideWebClientId: '', // https://id.fagforbundet.build/clients/oauth/<uuid>
    baseUrl: 'https://api.id.fagforbundet.build',
  } as IdApiInterface,
  development: {
    minSideWebClientId: 'dbab5a2c-4ad2-41eb-ac21-49072da58402',
    baseUrl: 'https://api.id.fagforbundet.dev',
  } as IdApiInterface,
  production: {
    minSideWebClientId: '9bb3eeb6-488d-4b3e-97c3-63d502a76b66',
    baseUrl: 'https://api.id.fagforbundet.no',
  } as IdApiInterface,
  staging: {
    minSideWebClientId: 'dbab5a2c-4ad2-41eb-ac21-49072da58402',
    baseUrl: 'https://api.id.fagforbundet.dev',
  } as IdApiInterface,
};
