import { FfNgxEndpointRecords } from '@fagforbundet/ngx-components';

export const CmsApiEndpoints = {
  GET_COLLECTIVEAGREEMENT: {
    path: '/collectiveagreement',
  },
  GET_COLLECTIVEAGREEMENTQUESTIONS: {
    path: '/collectiveagreementquestions',
  },
  GET_INSURANCE_LINKS: {
    path: '/insurancelinks',
  },
  GET_INSURANCE_QUESTIONS: {
    path: '/insurancequestions',
  },
  GET_MY_FEE: {
    path: '/my_fee',
  },
  GET_RECRUITING: {
    path: '/recruiting',
  },
  GET_RIGHTS_AND_ADVANTAGES: {
    path: '/rights_and_advantages',
  },
  GET_SCHOLARSHIP: {
    path: '/scholarship',
  },
  GET_SECTION: {
    path: '/section',
  },
  GET_SECTIONS: {
    path: '/sections',
  },
  GET_TRAVELEXPENSES: {
    path: '/travelexpenses',
  },
  GET_TRAVELEXPENSESQUESTIONS: {
    path: '/travelexpensesquestions',
  },
  GET_UNIONS: {
    path: '/unions',
  },
} satisfies FfNgxEndpointRecords;
