import { EnvironmentTargetInterface } from '@environments/assets/environment-target.interface';

export const agreementApiTargets: EnvironmentTargetInterface = {
  production: {
    baseUrl: 'https://avtale.fagforbundet.no/api',
  },
  staging: {
    baseUrl: 'https://avtale.fagforbundet.dev/api',
  },
};
