import { FfNgxEndpointRecords } from '@fagforbundet/ngx-components';

export const MembershipApiEndpoints = {
  GET_ACTIVE_EMPLOYMENTS: {
    path: '/v1/users/self/active-employments',
  },
  GET_ACTIVE_FORUM_POSITIONS: {
    path: '/v1/users/self/active-forum-positions',
  },
  GET_ACTIVE_MEMBERSHIP: {
    path: '/v1/users/self/active-membership',
  },
  GET_INSURANCES: {
    path: '/v1/users/self/insurances',
  },
  GET_IS_MEMBER: {
    path: '/v1/is-member',
  },
  GET_QR_CODE: {
    path: '/v1/qr-code',
  },
  GET_RECRUITMENT_POINTS: {
    path: '/v1/users/self/recruitment-points',
  },
  GET_RESERVATIONS: {
    path: '/v1/users/self/reservations',
  },
  GET_SHOP_STEWARDS_RELEVANT_TO_USER: {
    path: '/v1/shop-stewards-relevant-to-user',
  },
  GET_SUBSCRIPTIONS: {
    path: '/v1/users/self/available-subscriptions',
  },
  GET_UNION: {
    path: '/v1/organizational-units/:unionUuid',
  },
  GET_UNION_BOARD_MEMBERS: {
    path: '/v2/forum-positions',
  },
  POST_MEMBERSHIP_CANCELLATION: {
    path: '/v4/membership-cancellations',
  },
  POST_SUBSCRIPTIONS_ACTIVATE: {
    path: '/v1/users/self/available-subscriptions/:subscriptionUuid/activate',
  },
  POST_SUBSCRIPTIONS_DEACTIVATE: {
    path: '/v1/users/self/available-subscriptions/:subscriptionUuid/deactivate',
  },
} satisfies FfNgxEndpointRecords;
