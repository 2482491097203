import { AvailableApiName } from '@environments/assets/available-api-name.type';
import { EnvironmentInterface } from '@environments/assets/environment.interface';
import { cmsApiTargets } from '@config/apis/cms/cms-api-targets';
import { idApiTargets } from '@config/apis/id/id-api-targets';
import { membershipApiTargets } from '@config/apis/membership/membership-api-targets';
import { proxyApiTargets } from '@config/apis/proxy/proxy-api-targets';
import { agreementApiTargets } from '@config/apis/agreement/agreements-api-targets';
import { notificationApiTargets } from '@config/apis/notification/notification-api-targets';
import { EnvironmentType } from '@environments/assets/environment-type';

export const environment = {
  apis: {
    agreement: agreementApiTargets.production,
    cms: cmsApiTargets.production,
    id: idApiTargets.production,
    membership: membershipApiTargets.production,
    notification: notificationApiTargets.production,
    proxy: proxyApiTargets.production,
  } satisfies Record<AvailableApiName, EnvironmentInterface>,
  formats: {
    date: 'dd.MM.yyyy',
    dateTime: 'dd.MM.yyyy HH:mm',
  },
  frontend: {
    baseUrl: 'https://minside.fagforbundet.no',
    redirectUrl: 'https://minside.fagforbundet.no/login',
    postLogoutRedirectUri: 'https://minside.fagforbundet.no',
    shopStewardWebUrl: 'https://tillitsvalgt.fagforbundet.no',
    verifyEmailAddressUrl:
      'https://minside.fagforbundet.no/verify-email-address',
  },
  loadingScreen: {
    debug: false,
  },
  oidc: {
    debug: false,
  },
  pageTitlePrefix: 'Min Side',
  requestBundle: {
    ttl: 3600000,
  },
  router: {
    debug: false,
  },
  type: EnvironmentType.PRODUCTION,
  useMocks: false,
};
