import { EnvironmentTargetInterface } from '@environments/assets/environment-target.interface';

export const cmsApiTargets: EnvironmentTargetInterface = {
  production: {
    baseUrl: 'https://www1.fagforbundet.no/minsidenew',
  },
  staging: {
    baseUrl: 'https://www1.fagforbundet.no/minsidenew',
  },
};
