export const ProxyApiEndpoints = {
  GET_INSURANCES: {
    path: '/v2/users/:userUuid/insurances',
  },
  GET_IS_EMPLOYEE: {
    path: '/v1/users/:userUuid/is-employee',
  },
  GET_SCHOLARSHIP_APPLICATIONS: {
    path: '/v1/users/:userUuid/scholarship-applications',
  },
  POST_CONTACT_FORM_ATTACHMENTS: {
    path: '/v1/contact-form/attachments',
  },
  POST_CONTACT_FORM: {
    path: '/v1/contact-form',
  },
  PUT_USER_RESERVATIONS: {
    path: '/v1/users/:userUuid/reservations',
  },
};
